import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { ImageAttachmentsGMApi } from 'api/api';
import { CreateCanvaConfigurableStickerImageAttachmentInput } from 'api/model';
import { useAxios } from '@app/hooks';

export async function createCanvaConfigurableSticker(
  companyId: string,
  createCanvaConfigurableStickerImageAttachmentInput: CreateCanvaConfigurableStickerImageAttachmentInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new ImageAttachmentsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api
    .gmCreateCanvaConfigurableStickerImageAttachment(companyId, createCanvaConfigurableStickerImageAttachmentInput)
    .then((res) => res.data);
}

export default function useCreateCanvaConfigurableSticker(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: {
      companyId: string;
      createCanvaConfigurableStickerImageAttachmentInput: CreateCanvaConfigurableStickerImageAttachmentInput;
    }) => createCanvaConfigurableSticker(values.companyId, values.createCanvaConfigurableStickerImageAttachmentInput, axiosInstance),
    props
  );
}
