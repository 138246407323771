import React from 'react';

import { CardButton } from '@app/components';
import { useRouter } from 'next/router';

import { useLocale } from '@app/hooks';

type CategoryPropTypes = {
  canvaCategories: {
    canva_categories: Array<{
      id: string;
      created_at: string;
      name: string;
      tags: Array<any>;
      updated_at: string;
      url: string;
    }>;
  };
  scopeOptions: {
    scope: string;
  };
};

const Category: React.FC<CategoryPropTypes> = ({ canvaCategories, scopeOptions }) => {
  const { t } = useLocale();
  const router = useRouter();
  const onClick = (id: string) => {
    router.push({
      pathname: `/canva-categories/${id}`,
    });
  };
  const IMAGES = [
    {
      key: 'background',
      value: '/images/canva-bg.png',
    },
    {
      key: 'sticker',
      value: '/images/canva-stickers.png',
    },
    {
      key: 'product',
      value: '/images/canva-products.png',
    },
  ];

  const setImages = (itemName: any) => {
    const image = IMAGES.find((item) => item.key === itemName);
    return image?.value;
  };

  return (
    <div className="flex flex-row justify-center items-center my-34">
      <div className="flex min-w-240 gap-4">
        {canvaCategories?.canva_categories.map((item) => (
          <CardButton
            key={item.id}
            id={item.id}
            imageUrl={setImages(item.name) || '/images/default.png'}
            label={t(`labels.${item.name}`, scopeOptions)}
            onClick={() => onClick(item.id)}
            isDeletable={false}
          />
        ))}
      </div>
    </div>
  );
};

export default Category;
