import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { ImageAttachmentsGMApi } from 'api/api';
import { CreateCanvaStickerImageAttachmentInput } from 'api/model';
import { useAxios } from '@app/hooks';

export async function createCanvaSticker(
  companyId: string,
  createCanvaStickerImageAttachmentInput: CreateCanvaStickerImageAttachmentInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new ImageAttachmentsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.gmCreateCanvaStickerImageAttachment(companyId, createCanvaStickerImageAttachmentInput).then((res) => res.data);
}

export default function useCreateCanvaSticker(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; createCanvaStickerImageAttachmentInput: CreateCanvaStickerImageAttachmentInput }) =>
      createCanvaSticker(values.companyId, values.createCanvaStickerImageAttachmentInput, axiosInstance),
    props
  );
}
