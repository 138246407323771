import React from 'react';

import { Box, Form, Campaign } from '@app/components';
import { useFormContext } from 'react-hook-form';

import { useAuth, useLocale } from '@app/hooks';

type CanvaSubCategory = {
  id: string;
  name: string;
};

type CanvaItemFormTypes = {
  canvaCategories: { id: string; name: string }[];
  canvaSubCategories: { canva_sub_categories: CanvaSubCategory[] };
};

const CanvaItemUpdateStickerForm: React.FC<CanvaItemFormTypes> = ({ canvaCategories, canvaSubCategories }) => {
  const { company } = useAuth();
  const SCOPE_OPTIONS = { scope: 'components.CanvaItemForm' };
  const { t } = useLocale();
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  const hasGroupsFeature = !!company?.feature_flags?.has_group_feature;

  return (
    <form>
      <Box>
        <Form.Input
          type="text"
          id="name"
          label={t('form.name', SCOPE_OPTIONS)}
          className="mb-4.5"
          requiredSign={true}
          placeholder={t('form.namePlaceholder', SCOPE_OPTIONS)}
          {...register('name', {
            required: { value: true, message: t('form.nameErrorsRequired', SCOPE_OPTIONS) },
          })}
          error={errors.name}
        />
      </Box>
      {hasGroupsFeature && <Campaign.GroupSelect isRequired={true} />}
      <Box>
        <Form.Select
          name="canvaCategoryId"
          label={t('form.canvaCategory', SCOPE_OPTIONS)}
          rules={{
            required: { value: true, message: t('form.canvaCategoryErrorsRequired', SCOPE_OPTIONS) },
          }}
          control={control}
          error={errors.canvaCategoryId}
          options={canvaCategories?.map((category) => ({
            value: category.id,
            label: t(category.name, { scope: 'pages.canvas.index.labels' }),
          }))}
        />
      </Box>
      <Box>
        <Form.Select
          name="canvaSubCategoryId"
          label={t('form.canvaSubCategory', SCOPE_OPTIONS)}
          rules={{
            required: { value: true, message: t('form.canvaSubCategoryErrorsRequired', SCOPE_OPTIONS) },
          }}
          control={control}
          error={errors.canvaSubCategoryId}
          options={canvaSubCategories?.canva_sub_categories?.map((category) => ({
            value: category.id,
            label: category.name,
          }))}
        />
      </Box>
    </form>
  );
};

export default CanvaItemUpdateStickerForm;
