import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';
import { useAuth, useAxios } from '@app/hooks';

import { CanvaItemsGMApi } from 'api/api';

export function listBranchCanvaStickerPreviews(
  companyId: string,
  canvaItemId: string,
  page?: number,
  perPage?: number,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CanvaItemsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.getBranchStickerPreviews(companyId, canvaItemId, page, perPage).then((res: any) => res.data);
}

export default function useListBranchCanvaStickerPreviews({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { companyId } = useAuth();
  const { api: axiosInstance } = useAxios();

  return useQuery(
    ['useListBranchCanvaStickerPreviews', companyId, params.canvaItemId, params.page, params.perPage],
    () => listBranchCanvaStickerPreviews(companyId, params.canvaItemId, params.page, params.perPage, axiosInstance),
    props
  );
}
