import React from 'react';

import { Icons, Modal, Button } from 'components';
import { useLocale } from 'hooks';

type CardButtonPropTypes = {
  id: string;
  imageUrl: string;
  label: string;
  onClick?: () => void;
  isDeletable?: boolean;
  onDelete?: (id: string) => void;
};

const CardButton: React.FC<CardButtonPropTypes> = ({ id, imageUrl, label, onClick, isDeletable, onDelete }) => {
  const SCOPE_OPTIONS = { scope: 'components.CardButton' };
  const { t } = useLocale();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <div className="relative bg-white rounded-4 flex justify-center items-center hover:shadow-xl transition-shadow duration-500">
      {isDeletable && (
        <button
          className="absolute top-1 right-1 bg-red-200 rounded-full p-2 shadow-md hover:bg-red-600 transition-colors duration-300"
          title={t('labels.delete', SCOPE_OPTIONS)}
          onClick={() => setIsModalOpen(true)}
        >
          <Icons.Close className="w-4 h-4 fill-white" />
        </button>
      )}
      <button className="rounded-2 shadow-sm bg-white p-0 m-0 w-full h-full" onClick={onClick}>
        <div className="p-4 flex flex-col justify-between h-full">
          <div className="bg-gray-100 rounded-1 overflow-hidden h-full">
            <img className="w-full h-full object-contain max-h-70" src={imageUrl} alt={label} />
          </div>
          <p className="text-xl font-semibold text-gray-800 mt-8">{label}</p>
        </div>
      </button>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="flex flex-col gap-5 p-5">
          <p className="text-2xl font-semibold text-gray-800">{t('labels.question', SCOPE_OPTIONS)}</p>
          <div className="flex justify-end gap-5">
            <Button
              label={t('labels.delete', SCOPE_OPTIONS)}
              theme="red"
              onClick={() => {
                onDelete && onDelete(id);
                setIsModalOpen(false);
              }}
            />
            <Button label={t('labels.cancel', SCOPE_OPTIONS)} theme="blue" onClick={() => setIsModalOpen(false)} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CardButton;
