import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { CanvaItemsGMApi } from 'api/api';
import { useAuth, useAxios } from '@app/hooks';

export function getCanvaItem(companyId: string, canvaItemId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new CanvaItemsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.getCanvaItem(companyId, canvaItemId).then((res) => res.data);
}

export default function useGetCanvaItem({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { companyId } = useAuth();
  const { api: axiosInstance } = useAxios();

  return useQuery(
    ['useGetCanvaItem', companyId, params.canvaItemId],
    () => getCanvaItem(companyId, params.canvaItemId, axiosInstance),
    props
  );
}
