import React from 'react';

import { Box, Form } from '@app/components';
import { useLocale } from '@app/hooks';
import { useFormContext } from 'react-hook-form';

const SubCategoryForm: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'components.SubCategoryForm',
  };
  const { t } = useLocale();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <form>
      <Box>
        <Form.Input
          type="text"
          id="name"
          label={t('form.name', SCOPE_OPTIONS)}
          className="mb-4.5"
          requiredSign={true}
          placeholder={t('form.namePlaceholder', SCOPE_OPTIONS)}
          {...register('name', {
            required: { value: true, message: t('form.nameErrorsRequired', SCOPE_OPTIONS) },
          })}
          error={errors.name}
        />
      </Box>
    </form>
  );
};

export default SubCategoryForm;
