import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { useAxios } from '@app/hooks';

export function uploadCanvaItemImage(companyId: string, uploadCanvaItemImageInput: object, axiosInstance: AxiosInstance) {
  return axiosInstance
    .post(`/v2/general_manager_account/companies/${companyId}/attachments/images/canva_item`, uploadCanvaItemImageInput, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((res) => res.data);
}

export default function useUploadCanvaItemImage(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; uploadCanvaItemImageInput: object }) =>
      uploadCanvaItemImage(values.companyId, values.uploadCanvaItemImageInput, axiosInstance),
    props
  );
}
