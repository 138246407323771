import React, { useEffect } from 'react';
import cn from 'classnames';

import { Box, Form } from '@app/components';
import { useFormContext, useFieldArray } from 'react-hook-form';

import { useLocale } from '@app/hooks';

const CanvaStickerItemForm: React.FC = () => {
  const SCOPE_OPTIONS = { scope: 'components.CanvaStickerItemForm' };
  const { t } = useLocale();
  const {
    register,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useFormContext();
  const svgStringWatch = watch('configurable_sticker.svg_string');
  const mainStickerTypeWatch = watch('main_sticker_type');
  const MAIN_STICKER_TYPES = [
    { label: t('labels.branchSpesific', SCOPE_OPTIONS), value: 'branchSpesific' },
    { label: t('labels.configurable', SCOPE_OPTIONS), value: 'configurable' },
  ];

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'configurable_sticker.variables',
  });

  const handleStickerTypeClick = (value: string) => {
    setValue('main_sticker_type', value);
    remove();
    setValue('configurable_sticker.svg_string', '');
  };

  const parseSvgString = (svgString: string): void => {
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');

    const textElements = svgDoc.querySelectorAll('text tspan');

    if (mainStickerTypeWatch === 'branchSpesific') {
      const matchingValues = Array.from(textElements)
        .map((element) => element.textContent?.trim() || '')
        .filter((text) => text.includes('BRANCH_') || text.includes('SHORT_'))
        .map((text, index: number) => {
          const match = text.match(/(BRANCH_\d+|SHORT_\d+)/);
          const cleanValue = match ? match[0] : text;
          return { label: cleanValue, value: cleanValue, index: index };
        });

      const formVariables = matchingValues.map((item) => ({
        key: item.value,
        sticker_type: item.value.includes('BRANCH') ? item.value?.split('BRANCH_')[1].toLowerCase() : item.value?.toLowerCase(),
      }));

      setValue('sticker_type', formVariables[0]?.sticker_type);

      append(formVariables);
      return;
    }

    const matchingValues = Array.from(textElements)
      .map((element) => element.textContent?.trim() || '')
      .filter((text) => text.includes('PRICE') || text.includes('DISCOUNT'))
      .map((text, index: number) => {
        const match = text.match(/(PRICE_\d+|DISCOUNT_\d+)/);
        const cleanValue = match ? match[0] : text;
        return { label: cleanValue, value: cleanValue, index: index };
      });

    const formVariables = matchingValues.map((item) => ({
      key: item.value,
      [`${item.value}_min_value`]: '',
      [`${item.value}_max_value`]: '',
      [`${item.value}_preview_value`]: '',
    }));

    append(formVariables);
  };

  useEffect(() => {
    remove();

    if (svgStringWatch) {
      parseSvgString(svgStringWatch);
    }
  }, [svgStringWatch]);

  return (
    <form>
      <Box className="mb-4.5">
        <span className="font-semibold text-3.5 text-gray-900 flex items-center mb-4.5">{t('labels.stickerType', SCOPE_OPTIONS)}</span>
        <div className="grid grid-cols-2 gap-3.5 flex-1">
          {MAIN_STICKER_TYPES.map(({ label, value }) => (
            <button
              key={value}
              type="button"
              onClick={() => handleStickerTypeClick(value)}
              className={cn('p-3 rounded-3 border-0.5 flex flex-row items-center cursor-pointer transition-colors', {
                'border-gray-800 text-gray-900': watch('main_sticker_type') === value,
                'border-gray-300 text-gray-500': watch('main_sticker_type') !== value,
              })}
            >
              <span className="font-semibold text-3.5 text-gray-900">{label}</span>
            </button>
          ))}
        </div>
      </Box>
      <Box>
        <Form.Textarea
          id="configurable_sticker.svg_string"
          label={t('labels.svgString', SCOPE_OPTIONS)}
          className="mb-4.5"
          requiredSign={true}
          placeholder={t('labels.svgStringPlaceholder', SCOPE_OPTIONS)}
          {...register('configurable_sticker.svg_string', {
            required: { value: true, message: t('form.svgStringErrorsRequired', SCOPE_OPTIONS) },
          })}
          error={errors?.configurable_sticker?.svg_string}
        />
      </Box>
      {svgStringWatch &&
        mainStickerTypeWatch === MAIN_STICKER_TYPES[1].value &&
        fields.map((item: any, index) => (
          <Box key={item.index}>
            <span className="text-3 text-red-500 flex items-center mt-6">
              {errors?.configurable_sticker?.variables?.[index]?.[`${item.key}_max_value`]?.message}
            </span>
            <Form.Input
              type="text"
              id={`${item.key}_min_value`}
              label={`${item.key} ${t('labels.minValue', SCOPE_OPTIONS)}`}
              placeholder={`${item.key} ${t('labels.minValue', SCOPE_OPTIONS)}`}
              {...register(`configurable_sticker.variables[${index}].${item.key}_min_value`, {
                required: `Min Value is required`,
              })}
              requiredSign={true}
              className="mb-4.5"
              error={errors?.configurable_sticker?.variables?.[index]?.[`${item.key}_min_value`]?.message}
            />
            <span className="text-3 text-red-500 flex items-center mt-6">
              {errors?.configurable_sticker?.variables?.[index]?.[`${item.key}_max_value`]?.message}
            </span>
            <Form.Input
              type="text"
              id={`${item.key}_max_value`}
              label={`${item.key} ${t('labels.maxValue', SCOPE_OPTIONS)}`}
              placeholder={`${item.key} ${t('labels.maxValue', SCOPE_OPTIONS)}`}
              {...register(`configurable_sticker.variables[${index}].${item.key}_max_value`, {
                required: `Max Value is required`,
              })}
              requiredSign={true}
              error={errors?.configurable_sticker?.variables?.[index]?.[`${item.key}_max_value`]?.message}
            />
            <span className="text-3 text-red-500 flex items-center mt-6">
              {errors?.configurable_sticker?.variables?.[index]?.[`${item.key}_preview_value`]?.message}
            </span>
            <Form.Input
              type="text"
              id={`${item.key}_preview_value`}
              label={`${item.key} ${t('labels.previewValue', SCOPE_OPTIONS)}`}
              placeholder={`${item.key} ${t('labels.previewValue', SCOPE_OPTIONS)}`}
              {...register(`configurable_sticker.variables[${index}].${item.key}_preview_value`, {
                required: `Preview Value is required`,
              })}
              requiredSign={true}
              error={errors?.configurable_sticker?.variables?.[index]?.[`${item.key}_preview_value`]?.message}
            />
          </Box>
        ))}
      {svgStringWatch &&
        mainStickerTypeWatch === MAIN_STICKER_TYPES[0].value &&
        fields.map((item: any, index) => (
          <Box key={item.key}>
            <Form.Input
              type="text"
              id={`${item.key}`}
              label={`${t('labels.stickerType', SCOPE_OPTIONS)}`}
              placeholder="sticker type"
              {...register(`configurable_sticker.variables[${index}].${item.key}`, {
                required: `Sticker type is required`,
              })}
              requiredSign={true}
              className="mb-4.5"
              error={errors?.configurable_sticker?.variables?.[index]?.[`${item.key}`]?.message}
              disabled={true}
              value={item.sticker_type}
            />
            <span className="text-3 text-red-500 flex items-center mt-6">
              {errors?.configurable_sticker?.variables?.[index]?.[`${item.key}`]?.message}
            </span>
          </Box>
        ))}
    </form>
  );
};

export default CanvaStickerItemForm;
