import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';
import { useAuth, useAxios } from '@app/hooks';

import { CanvaCategoriesGMApi } from 'api/api';

export function listCanvaCategories(companyId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new CanvaCategoriesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.list(companyId).then((res) => res.data);
}

export default function useListCanvaCategories(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { companyId } = useAuth();
  const { api: axiosInstance } = useAxios();

  return useQuery(['listCanvaCategories'], () => listCanvaCategories(companyId, axiosInstance), props);
}
