/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CanvaItem } from '../model';
// @ts-ignore
import { CanvaItemBranchPreviewResponse } from '../model';
// @ts-ignore
import { CanvaItemErrorResponse } from '../model';
// @ts-ignore
import { CanvaItemInput } from '../model';
// @ts-ignore
import { CanvaItemListResponse } from '../model';
// @ts-ignore
import { PunditResponse } from '../model';
/**
 * CanvaItemsGMApi - axios parameter creator
 * @export
 */
export const CanvaItemsGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create canva item for company
         * @summary Create canva item for company
         * @param {string} companyId The id of the company
         * @param {CanvaItemInput} canvaItemInput Create a canva item for company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanvaItem: async (companyId: string, canvaItemInput: CanvaItemInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createCanvaItem', 'companyId', companyId)
            // verify required parameter 'canvaItemInput' is not null or undefined
            assertParamExists('createCanvaItem', 'canvaItemInput', canvaItemInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/canva_items`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canvaItemInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete canva item by id
         * @summary Delete canva item by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCanvaItem: async (companyId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteCanvaItem', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCanvaItem', 'id', id)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/canva_items/{id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate missing stickers for canva item by id
         * @summary Generate missing stickers for canva item by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMissingStickers: async (companyId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('generateMissingStickers', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('generateMissingStickers', 'id', id)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/canva_items/{id}/generate_missing_stickers`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get branch sticker previews for canva item by id
         * @summary Get branch sticker previews for canva item by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva item
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchStickerPreviews: async (companyId: string, id: string, page?: number, perPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getBranchStickerPreviews', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBranchStickerPreviews', 'id', id)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/canva_items/{id}/branch_sticker_previews`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get canva item by id
         * @summary Get canva item by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanvaItem: async (companyId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCanvaItem', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCanvaItem', 'id', id)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/canva_items/{id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get canva items for company
         * @summary Get canva items for company
         * @param {string} companyId The id of the company
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanvaItems: async (companyId: string, query?: object, page?: number, perPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCanvaItems', 'companyId', companyId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/canva_items`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update canva item by id
         * @summary Update canva item by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva item
         * @param {CanvaItemInput} canvaItemInput Update a canva item for company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanvaItem: async (companyId: string, id: string, canvaItemInput: CanvaItemInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCanvaItem', 'companyId', companyId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCanvaItem', 'id', id)
            // verify required parameter 'canvaItemInput' is not null or undefined
            assertParamExists('updateCanvaItem', 'canvaItemInput', canvaItemInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/canva_items/{id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canvaItemInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CanvaItemsGMApi - functional programming interface
 * @export
 */
export const CanvaItemsGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CanvaItemsGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Create canva item for company
         * @summary Create canva item for company
         * @param {string} companyId The id of the company
         * @param {CanvaItemInput} canvaItemInput Create a canva item for company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCanvaItem(companyId: string, canvaItemInput: CanvaItemInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvaItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCanvaItem(companyId, canvaItemInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete canva item by id
         * @summary Delete canva item by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCanvaItem(companyId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCanvaItem(companyId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate missing stickers for canva item by id
         * @summary Generate missing stickers for canva item by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateMissingStickers(companyId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateMissingStickers(companyId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get branch sticker previews for canva item by id
         * @summary Get branch sticker previews for canva item by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva item
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBranchStickerPreviews(companyId: string, id: string, page?: number, perPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvaItemBranchPreviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBranchStickerPreviews(companyId, id, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get canva item by id
         * @summary Get canva item by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCanvaItem(companyId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvaItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCanvaItem(companyId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get canva items for company
         * @summary Get canva items for company
         * @param {string} companyId The id of the company
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCanvaItems(companyId: string, query?: object, page?: number, perPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvaItemListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCanvaItems(companyId, query, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update canva item by id
         * @summary Update canva item by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva item
         * @param {CanvaItemInput} canvaItemInput Update a canva item for company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCanvaItem(companyId: string, id: string, canvaItemInput: CanvaItemInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvaItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCanvaItem(companyId, id, canvaItemInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CanvaItemsGMApi - factory interface
 * @export
 */
export const CanvaItemsGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CanvaItemsGMApiFp(configuration)
    return {
        /**
         * Create canva item for company
         * @summary Create canva item for company
         * @param {string} companyId The id of the company
         * @param {CanvaItemInput} canvaItemInput Create a canva item for company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCanvaItem(companyId: string, canvaItemInput: CanvaItemInput, options?: any): AxiosPromise<CanvaItem> {
            return localVarFp.createCanvaItem(companyId, canvaItemInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete canva item by id
         * @summary Delete canva item by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCanvaItem(companyId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCanvaItem(companyId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate missing stickers for canva item by id
         * @summary Generate missing stickers for canva item by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMissingStickers(companyId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.generateMissingStickers(companyId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get branch sticker previews for canva item by id
         * @summary Get branch sticker previews for canva item by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva item
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchStickerPreviews(companyId: string, id: string, page?: number, perPage?: number, options?: any): AxiosPromise<CanvaItemBranchPreviewResponse> {
            return localVarFp.getBranchStickerPreviews(companyId, id, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Get canva item by id
         * @summary Get canva item by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanvaItem(companyId: string, id: string, options?: any): AxiosPromise<CanvaItem> {
            return localVarFp.getCanvaItem(companyId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get canva items for company
         * @summary Get canva items for company
         * @param {string} companyId The id of the company
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanvaItems(companyId: string, query?: object, page?: number, perPage?: number, options?: any): AxiosPromise<CanvaItemListResponse> {
            return localVarFp.getCanvaItems(companyId, query, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Update canva item by id
         * @summary Update canva item by id
         * @param {string} companyId The id of the company
         * @param {string} id The id of the canva item
         * @param {CanvaItemInput} canvaItemInput Update a canva item for company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCanvaItem(companyId: string, id: string, canvaItemInput: CanvaItemInput, options?: any): AxiosPromise<CanvaItem> {
            return localVarFp.updateCanvaItem(companyId, id, canvaItemInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CanvaItemsGMApi - interface
 * @export
 * @interface CanvaItemsGMApi
 */
export interface CanvaItemsGMApiInterface {
    /**
     * Create canva item for company
     * @summary Create canva item for company
     * @param {string} companyId The id of the company
     * @param {CanvaItemInput} canvaItemInput Create a canva item for company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaItemsGMApiInterface
     */
    createCanvaItem(companyId: string, canvaItemInput: CanvaItemInput, options?: any): AxiosPromise<CanvaItem>;

    /**
     * Delete canva item by id
     * @summary Delete canva item by id
     * @param {string} companyId The id of the company
     * @param {string} id The id of the canva item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaItemsGMApiInterface
     */
    deleteCanvaItem(companyId: string, id: string, options?: any): AxiosPromise<void>;

    /**
     * Generate missing stickers for canva item by id
     * @summary Generate missing stickers for canva item by id
     * @param {string} companyId The id of the company
     * @param {string} id The id of the canva item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaItemsGMApiInterface
     */
    generateMissingStickers(companyId: string, id: string, options?: any): AxiosPromise<void>;

    /**
     * Get branch sticker previews for canva item by id
     * @summary Get branch sticker previews for canva item by id
     * @param {string} companyId The id of the company
     * @param {string} id The id of the canva item
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaItemsGMApiInterface
     */
    getBranchStickerPreviews(companyId: string, id: string, page?: number, perPage?: number, options?: any): AxiosPromise<CanvaItemBranchPreviewResponse>;

    /**
     * Get canva item by id
     * @summary Get canva item by id
     * @param {string} companyId The id of the company
     * @param {string} id The id of the canva item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaItemsGMApiInterface
     */
    getCanvaItem(companyId: string, id: string, options?: any): AxiosPromise<CanvaItem>;

    /**
     * Get canva items for company
     * @summary Get canva items for company
     * @param {string} companyId The id of the company
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaItemsGMApiInterface
     */
    getCanvaItems(companyId: string, query?: object, page?: number, perPage?: number, options?: any): AxiosPromise<CanvaItemListResponse>;

    /**
     * Update canva item by id
     * @summary Update canva item by id
     * @param {string} companyId The id of the company
     * @param {string} id The id of the canva item
     * @param {CanvaItemInput} canvaItemInput Update a canva item for company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaItemsGMApiInterface
     */
    updateCanvaItem(companyId: string, id: string, canvaItemInput: CanvaItemInput, options?: any): AxiosPromise<CanvaItem>;

}

/**
 * CanvaItemsGMApi - object-oriented interface
 * @export
 * @class CanvaItemsGMApi
 * @extends {BaseAPI}
 */
export class CanvaItemsGMApi extends BaseAPI implements CanvaItemsGMApiInterface {
    /**
     * Create canva item for company
     * @summary Create canva item for company
     * @param {string} companyId The id of the company
     * @param {CanvaItemInput} canvaItemInput Create a canva item for company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaItemsGMApi
     */
    public createCanvaItem(companyId: string, canvaItemInput: CanvaItemInput, options?: any) {
        return CanvaItemsGMApiFp(this.configuration).createCanvaItem(companyId, canvaItemInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete canva item by id
     * @summary Delete canva item by id
     * @param {string} companyId The id of the company
     * @param {string} id The id of the canva item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaItemsGMApi
     */
    public deleteCanvaItem(companyId: string, id: string, options?: any) {
        return CanvaItemsGMApiFp(this.configuration).deleteCanvaItem(companyId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate missing stickers for canva item by id
     * @summary Generate missing stickers for canva item by id
     * @param {string} companyId The id of the company
     * @param {string} id The id of the canva item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaItemsGMApi
     */
    public generateMissingStickers(companyId: string, id: string, options?: any) {
        return CanvaItemsGMApiFp(this.configuration).generateMissingStickers(companyId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get branch sticker previews for canva item by id
     * @summary Get branch sticker previews for canva item by id
     * @param {string} companyId The id of the company
     * @param {string} id The id of the canva item
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaItemsGMApi
     */
    public getBranchStickerPreviews(companyId: string, id: string, page?: number, perPage?: number, options?: any) {
        return CanvaItemsGMApiFp(this.configuration).getBranchStickerPreviews(companyId, id, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get canva item by id
     * @summary Get canva item by id
     * @param {string} companyId The id of the company
     * @param {string} id The id of the canva item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaItemsGMApi
     */
    public getCanvaItem(companyId: string, id: string, options?: any) {
        return CanvaItemsGMApiFp(this.configuration).getCanvaItem(companyId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get canva items for company
     * @summary Get canva items for company
     * @param {string} companyId The id of the company
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaItemsGMApi
     */
    public getCanvaItems(companyId: string, query?: object, page?: number, perPage?: number, options?: any) {
        return CanvaItemsGMApiFp(this.configuration).getCanvaItems(companyId, query, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update canva item by id
     * @summary Update canva item by id
     * @param {string} companyId The id of the company
     * @param {string} id The id of the canva item
     * @param {CanvaItemInput} canvaItemInput Update a canva item for company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaItemsGMApi
     */
    public updateCanvaItem(companyId: string, id: string, canvaItemInput: CanvaItemInput, options?: any) {
        return CanvaItemsGMApiFp(this.configuration).updateCanvaItem(companyId, id, canvaItemInput, options).then((request) => request(this.axios, this.basePath));
    }
}
