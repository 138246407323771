import React from 'react';
import cn from 'classnames';

import { Box, Form, Icons, Campaign } from '@app/components';
import { useFormContext } from 'react-hook-form';

import { api, useAuth, useLocale } from '@app/hooks';

type CanvaItemFormTypes = {
  isSticker?: boolean;
};

const CanvaItemForm: React.FC<CanvaItemFormTypes> = (isSticker) => {
  const { company } = useAuth();
  const SCOPE_OPTIONS = { scope: 'components.CanvaItemForm' };
  const { t } = useLocale();
  const {
    register,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();
  const fileWatch = watch('attachment.id');
  const hasGroupsFeature = !!company?.feature_flags?.has_group_feature;

  const { mutate: uploadCanvaItemImage, isLoading: isUploadCanvaItemImageLoading } = api.useUploadCanvaItemImage({
    onSuccess: (data: any) => {
      setValue('attachment', {
        id: data.id,
        url: data.file.url,
      });
    },
  });

  function fileStatusText() {
    if (isUploadCanvaItemImageLoading) {
      return t('form.uploading', SCOPE_OPTIONS);
    }

    if (fileWatch) {
      return t('form.uploaded', SCOPE_OPTIONS);
    }

    return t('form.upload', SCOPE_OPTIONS);
  }

  function uploadFile(file: File | undefined) {
    if (!file) return;
    const formData = new FormData();

    formData.append('file', file);
    uploadCanvaItemImage({ companyId: company.id, uploadCanvaItemImageInput: formData });
  }

  return (
    <form>
      <Box>
        <Form.Input
          type="text"
          id="name"
          label={t('form.name', SCOPE_OPTIONS)}
          className="mb-4.5"
          requiredSign={true}
          placeholder={t('form.namePlaceholder', SCOPE_OPTIONS)}
          {...register('name', {
            required: { value: true, message: t('form.nameErrorsRequired', SCOPE_OPTIONS) },
          })}
          error={errors.name}
        />
      </Box>
      {hasGroupsFeature && <Campaign.GroupSelect isRequired={true} />}
      {!isSticker?.isSticker && (
        <Box>
          <div className="flex flex-col gap-2">
            <div className="flex justify-between">
              <span className="font-medium">
                {t('form.attachment', SCOPE_OPTIONS)} <span className="text-red-500">*</span>
              </span>
              {errors?.attachment?.id?.message && fileWatch === undefined && (
                <div className="flex items-center">
                  <span className="inline text-red-500 text-3 whitespace-nowrap">{errors.attachment.id.message}</span>
                </div>
              )}
            </div>
            <label
              htmlFor="upload"
              className={cn('cursor-pointer w-full bg-indigo-100 flex items-center justify-center py-6 rounded-xl border border-gray-400', {
                'opacity-40 cursor-not-allowed': isUploadCanvaItemImageLoading,
              })}
            >
              <Icons.File className={cn('w-6 h-6 mr-2', { 'text-blue-400': !fileWatch, 'text-green-600': fileWatch })} />
              <span className={cn({ 'text-blue-400': !fileWatch, 'text-green-600': fileWatch })}>{fileStatusText()}</span>
            </label>
            <input id="upload" type="file" className="hidden" onChange={(e) => uploadFile(e?.target?.files?.[0])}></input>
          </div>
        </Box>
      )}
    </form>
  );
};

export default CanvaItemForm;
