import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';
import { useAuth, useAxios } from '@app/hooks';

import { CanvaItemsGMApi } from 'api/api';

export function listCanvaItems(
  companyId: string,
  page?: number,
  perPage?: number,
  query?: object,
  type?: string,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CanvaItemsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.getCanvaItems(companyId, query, page, perPage, type).then((res: any) => res.data);
}

export default function useListCanvaItems({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { companyId } = useAuth();
  const { api: axiosInstance } = useAxios();

  return useQuery(
    ['useListCanvaItems', companyId, params.page, params.perPage, params.query, params.type],
    () => listCanvaItems(companyId, params.page, params.perPage, params.query, params.type, axiosInstance),
    props
  );
}
