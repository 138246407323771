import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { CanvaItemsGMApi } from '@app/api';
import { CanvaItemInput } from '@app/api';

import { useAxios } from '@app/hooks';

export async function updateCanvaItem(
  companyId: string,
  canvaItemId: string,
  canvaItemInput: CanvaItemInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CanvaItemsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.updateCanvaItem(companyId, canvaItemId, canvaItemInput).then((res) => res.data);
}

export default function useUpdateCanvaItem(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; canvaItemId: string; canvaItemInput: CanvaItemInput }) =>
      updateCanvaItem(values.companyId, values.canvaItemId, values.canvaItemInput, axiosInstance),
    props
  );
}
