import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { CanvaCategoriesGMApi } from '@app/api';
import { useAxios } from '@app/hooks';

export function deleteCanvaSubCategory(
  companyId: string,
  canvaCategoryId: string,
  canvaSubCategoryId: string,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CanvaCategoriesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.destroySubCategory(companyId, canvaCategoryId, canvaSubCategoryId).then((res) => res.data);
}

export default function useDeleteCanvaSubCategory() {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    ({ companyId, canvaCategoryId, canvaSubCategoryId }: { companyId: string; canvaCategoryId: string; canvaSubCategoryId: string }) =>
      deleteCanvaSubCategory(companyId, canvaCategoryId, canvaSubCategoryId, axiosInstance)
  );
}
