import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { CanvaItemsGMApi } from '@app/api';
import { useAxios } from '@app/hooks';

export function deleteCanvaItem(companyId: string, canvaItemId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new CanvaItemsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.deleteCanvaItem(companyId, canvaItemId).then((res) => res.data);
}

export default function useDeleteCanvaItem() {
  const { api: axiosInstance } = useAxios();

  return useMutation(({ companyId, canvaItemId }: { companyId: string; canvaItemId: string }) =>
    deleteCanvaItem(companyId, canvaItemId, axiosInstance)
  );
}
