import { AxiosInstance } from 'axios';
import { useMutation } from 'react-query';

import { CanvaCategoriesGMApi } from '@app/api';
import { CanvaSubCategoryInput } from '@app/api';
import { useAxios } from '@app/hooks';

export async function createCanvaSubCategory(
  companyId: string,
  id: string,
  canvaSubCategoryInput: CanvaSubCategoryInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CanvaCategoriesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.createSubCategory(companyId, id, canvaSubCategoryInput).then((res) => res.data);
}

export default function useCreateCanvaSubCategory(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; categoryId: string; canvaSubCategoryInput: CanvaSubCategoryInput }) =>
      createCanvaSubCategory(values.companyId, values.categoryId, values.canvaSubCategoryInput, axiosInstance),
    props
  );
}
