import Activities from './Activities';
import Add from './Add';
import Branches from './Branches';
import Calendar from './Calendar';
import Call from './Call';
import Campaign from './Campaign';
import CheckCircle from './CheckCircle';
import Checkmark from './Checkmark';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import ClockCircle from './ClockCircle';
import Coins from './Coins';
import Collapse from './Collapse';
import ColorPicker from './ColorPicker';
import Cross from './Cross';
import CrossCircle from './CrossCircle';
import Cursor from './Cursor';
import CircleInfo from './CircleInfo';
import Dashboard from './Dashboard';
import DisplayNetwork from './DisplayNetwork';
import Discovery from './Discovery';
import Directions from './Directions';
import DollarCircle from './DollarCircle';
import Dots from './Dots';
import Down from './Down';
import DragAndDrop from './DragAndDrop';
import Eye from './Eye';
import ExternalLink from './ExternalLink';
import Facebook from './Facebook';
import FacebookSquare from './FacebookSquare';
import Forms from './Forms';
import Folder from './Folder';
import Fire from './Fire';
import Google from './Google';
import GoogleIcon from './GoogleIcon';
import GoogleVoice from './GoogleVoice';
import Group from './Group';
import GoogleLens from './GoogleLens';
import Gmail from './Gmail';
import Home from './Home';
import Instagram from './Instagram';
import InstagramIcon from './InstagramIcon';
import Keyboard from './Keyboard';
import Logo from './Logo';
import LogoIcon from './LogoIcon';
import Location from './Location';
import LocationPin from './LocationPin';
import Magnifyer from './Magnifyer';
import Maps from './Maps';
import Menu from './Menu';
import MediaLibrary from './MediaLibrary';
import NotificationBell from './NotificationBell';
import PlayCircle from './PlayCircle';
import Plus from './Plus';
import PlusCircle from './PlusCircle';
import Save from './Save';
import Search from './Search';
import Send from './Send';
import Settings from './Settings';
import Star from './Star';
import Templates from './Templates';
import TreeDot from './TreeDot';
import Users from './Users';
import VerticalDots from './VerticalDots';
import Transh from './Transh';
import XMark from './XMark';
import Website from './Website';
import Youtube from './Youtube';
import Dla from './Dla';
import File from './File';
import Like from './Like';
import Comment from './Comment';
import Share from './Share';
import Close from './Close';
import InstagramLike from './InstagramLike';
import InstagramSave from './InstagramSave';
import InstagramShare from './InstagramShare';
import InstagramText from './InstagramText';
import InstagramVisit from './InstagramVisit';
import InstagramComment from './InstagramComment';
import ChevronUp from './ChevronUp';
import Export from './Export';
import Disk from './Disk';
import AmericanExpressCard from './AmericanExpressCard';
import DiscoverCard from './DiscoverCard';
import MastercardCard from './MastercardCard';
import VisaCard from './VisaCard';
import Wallet from './Wallet';
import Post from './Post';
import CreditCard from './CreditCard';
import CanvaSide from './CanvaSide';

export default {
  Activities,
  Add,
  Branches,
  Calendar,
  Call,
  Campaign,
  CheckCircle,
  Checkmark,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ClockCircle,
  Coins,
  Collapse,
  ColorPicker,
  Cross,
  CrossCircle,
  Cursor,
  CircleInfo,
  Dashboard,
  DisplayNetwork,
  Discovery,
  Directions,
  DollarCircle,
  Dots,
  Down,
  DragAndDrop,
  Eye,
  ExternalLink,
  Facebook,
  FacebookSquare,
  Forms,
  Folder,
  Fire,
  Google,
  GoogleIcon,
  Gmail,
  GoogleVoice,
  Group,
  GoogleLens,
  Home,
  Instagram,
  InstagramIcon,
  Keyboard,
  Logo,
  LogoIcon,
  Location,
  LocationPin,
  Magnifyer,
  Maps,
  Menu,
  MediaLibrary,
  NotificationBell,
  PlayCircle,
  Plus,
  PlusCircle,
  Save,
  Search,
  Send,
  Settings,
  Star,
  Templates,
  TreeDot,
  Users,
  VerticalDots,
  Transh,
  XMark,
  Website,
  Youtube,
  Dla,
  File,
  Like,
  Comment,
  Share,
  Close,
  InstagramLike,
  InstagramSave,
  InstagramShare,
  InstagramText,
  InstagramComment,
  InstagramVisit,
  Export,
  Disk,
  AmericanExpressCard,
  DiscoverCard,
  MastercardCard,
  VisaCard,
  Post,
  Wallet,
  CreditCard,
  CanvaSide,
};
