import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { CanvaCategoriesGMApi } from 'api/api';
import { useAuth, useAxios } from '@app/hooks';

export function showCanvaSubCategory(companyId: string, canvaSubCategoryId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new CanvaCategoriesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.show(companyId, canvaSubCategoryId).then((res) => res.data);
}

export default function useShowCanvaSubCategory({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useQuery(
    ['useShowCanvaSubCategory', companyId, params.canvaSubCategoryId],
    () => showCanvaSubCategory(companyId, params.canvaSubCategoryId, axiosInstance),
    props
  );
}
