/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CanvaItemImageAttachment } from '../model';
// @ts-ignore
import { CreateCanvaConfigurableStickerImageAttachmentInput } from '../model';
// @ts-ignore
import { CreateCanvaStickerImageAttachmentInput } from '../model';
// @ts-ignore
import { ImageAttachment } from '../model';
// @ts-ignore
import { PunditResponse } from '../model';
// @ts-ignore
import { UploadBannerbearImageAttachmentErrorResponse } from '../model';
// @ts-ignore
import { UploadBannerbearImageAttachmentInput } from '../model';
// @ts-ignore
import { UploadBannerbearImageAttachmentSuccessResponse } from '../model';
// @ts-ignore
import { UploadCanvaItemImageAttachmentErrorResponse } from '../model';
// @ts-ignore
import { UploadImageAttachmentErrorResponse } from '../model';
/**
 * ImageAttachmentsGMApi - axios parameter creator
 * @export
 */
export const ImageAttachmentsGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create image for canva configurable sticker
         * @summary Create image for canva configurable sticker
         * @param {string} companyId The id of the company
         * @param {CreateCanvaConfigurableStickerImageAttachmentInput} createCanvaConfigurableStickerImageAttachmentInput Create image for canva configurable sticker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmCreateCanvaConfigurableStickerImageAttachment: async (companyId: string, createCanvaConfigurableStickerImageAttachmentInput: CreateCanvaConfigurableStickerImageAttachmentInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('gmCreateCanvaConfigurableStickerImageAttachment', 'companyId', companyId)
            // verify required parameter 'createCanvaConfigurableStickerImageAttachmentInput' is not null or undefined
            assertParamExists('gmCreateCanvaConfigurableStickerImageAttachment', 'createCanvaConfigurableStickerImageAttachmentInput', createCanvaConfigurableStickerImageAttachmentInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/attachments/images/canva_configurable_sticker`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCanvaConfigurableStickerImageAttachmentInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create image for canva sticker
         * @summary Create image for canva sticker
         * @param {string} companyId The id of the company
         * @param {CreateCanvaStickerImageAttachmentInput} createCanvaStickerImageAttachmentInput Create image for canva sticker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmCreateCanvaStickerImageAttachment: async (companyId: string, createCanvaStickerImageAttachmentInput: CreateCanvaStickerImageAttachmentInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('gmCreateCanvaStickerImageAttachment', 'companyId', companyId)
            // verify required parameter 'createCanvaStickerImageAttachmentInput' is not null or undefined
            assertParamExists('gmCreateCanvaStickerImageAttachment', 'createCanvaStickerImageAttachmentInput', createCanvaStickerImageAttachmentInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/attachments/images/canva_sticker`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCanvaStickerImageAttachmentInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a photo for bannerbear
         * @summary Upload a photo for bannerbear
         * @param {string} companyId The id of the company
         * @param {UploadBannerbearImageAttachmentInput} uploadBannerbearImageAttachmentInput Upload a photo for bannerbear
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadBannerbearImageAttachment: async (companyId: string, uploadBannerbearImageAttachmentInput: UploadBannerbearImageAttachmentInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('gmUploadBannerbearImageAttachment', 'companyId', companyId)
            // verify required parameter 'uploadBannerbearImageAttachmentInput' is not null or undefined
            assertParamExists('gmUploadBannerbearImageAttachment', 'uploadBannerbearImageAttachmentInput', uploadBannerbearImageAttachmentInput)
            const localVarPath = `/v2/gm_user/companies/{company_id}/attachments/images/bannerbear`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadBannerbearImageAttachmentInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload image for canva
         * @summary Upload image for canva
         * @param {string} companyId The id of the company
         * @param {object} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadCanvaItemImageAttachment: async (companyId: string, file: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('gmUploadCanvaItemImageAttachment', 'companyId', companyId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('gmUploadCanvaItemImageAttachment', 'file', file)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/attachments/images/canva_item`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload image for canva template
         * @summary Upload image for canva template
         * @param {string} companyId The id of the company
         * @param {object} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadCanvaTemplateImageAttachment: async (companyId: string, file: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('gmUploadCanvaTemplateImageAttachment', 'companyId', companyId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('gmUploadCanvaTemplateImageAttachment', 'file', file)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/attachments/images/canva_template`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload the facebook image generated by bannerbear
         * @summary Upload the facebook image generated by bannerbear
         * @param {string} companyId The id of the company
         * @param {UploadBannerbearImageAttachmentInput} uploadBannerbearImageAttachmentInput Upload the facebook image generated by bannerbear
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadFacebookBannerbearImageAttachment: async (companyId: string, uploadBannerbearImageAttachmentInput: UploadBannerbearImageAttachmentInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('gmUploadFacebookBannerbearImageAttachment', 'companyId', companyId)
            // verify required parameter 'uploadBannerbearImageAttachmentInput' is not null or undefined
            assertParamExists('gmUploadFacebookBannerbearImageAttachment', 'uploadBannerbearImageAttachmentInput', uploadBannerbearImageAttachmentInput)
            const localVarPath = `/v2/gm_user/companies/{company_id}/attachments/images/facebook_bannerbear`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadBannerbearImageAttachmentInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a photo for facebook
         * @summary Upload a photo for facebook
         * @param {string} companyId The id of the company
         * @param {object} file 
         * @param {object} [extra] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadFacebookImageAttachment: async (companyId: string, file: object, extra?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('gmUploadFacebookImageAttachment', 'companyId', companyId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('gmUploadFacebookImageAttachment', 'file', file)
            const localVarPath = `/gm_user/companies/{company_id}/attachments/images/facebook`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (extra !== undefined) { 
                localVarFormParams.append('extra', new Blob([JSON.stringify(extra)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a photo for google
         * @summary Upload a photo for google
         * @param {string} companyId The id of the company
         * @param {object} file 
         * @param {object} [extra] 
         * @param {string} [placementType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadGoogleImageAttachment: async (companyId: string, file: object, extra?: object, placementType?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('gmUploadGoogleImageAttachment', 'companyId', companyId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('gmUploadGoogleImageAttachment', 'file', file)
            const localVarPath = `/gm_user/companies/{company_id}/attachments/images/google`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (extra !== undefined) { 
                localVarFormParams.append('extra', new Blob([JSON.stringify(extra)], { type: "application/json", }));
            }
    
            if (placementType !== undefined) { 
                localVarFormParams.append('placement_type', placementType as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload the instagram image generated by bannerbear
         * @summary Upload the instagram image generated by bannerbear
         * @param {string} companyId The id of the company
         * @param {UploadBannerbearImageAttachmentInput} uploadBannerbearImageAttachmentInput Upload the instagram image generated by bannerbear
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadInstagramBannerbearImageAttachment: async (companyId: string, uploadBannerbearImageAttachmentInput: UploadBannerbearImageAttachmentInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('gmUploadInstagramBannerbearImageAttachment', 'companyId', companyId)
            // verify required parameter 'uploadBannerbearImageAttachmentInput' is not null or undefined
            assertParamExists('gmUploadInstagramBannerbearImageAttachment', 'uploadBannerbearImageAttachmentInput', uploadBannerbearImageAttachmentInput)
            const localVarPath = `/v2/gm_user/companies/{company_id}/attachments/images/instagram_bannerbear`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadBannerbearImageAttachmentInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a photo for instagram
         * @summary Upload a photo for instagram
         * @param {string} companyId The id of the company
         * @param {object} file 
         * @param {object} [extra] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadInstagramImageAttachment: async (companyId: string, file: object, extra?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('gmUploadInstagramImageAttachment', 'companyId', companyId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('gmUploadInstagramImageAttachment', 'file', file)
            const localVarPath = `/gm_user/companies/{company_id}/attachments/images/instagram`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (extra !== undefined) { 
                localVarFormParams.append('extra', new Blob([JSON.stringify(extra)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageAttachmentsGMApi - functional programming interface
 * @export
 */
export const ImageAttachmentsGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageAttachmentsGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Create image for canva configurable sticker
         * @summary Create image for canva configurable sticker
         * @param {string} companyId The id of the company
         * @param {CreateCanvaConfigurableStickerImageAttachmentInput} createCanvaConfigurableStickerImageAttachmentInput Create image for canva configurable sticker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gmCreateCanvaConfigurableStickerImageAttachment(companyId: string, createCanvaConfigurableStickerImageAttachmentInput: CreateCanvaConfigurableStickerImageAttachmentInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvaItemImageAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gmCreateCanvaConfigurableStickerImageAttachment(companyId, createCanvaConfigurableStickerImageAttachmentInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create image for canva sticker
         * @summary Create image for canva sticker
         * @param {string} companyId The id of the company
         * @param {CreateCanvaStickerImageAttachmentInput} createCanvaStickerImageAttachmentInput Create image for canva sticker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gmCreateCanvaStickerImageAttachment(companyId: string, createCanvaStickerImageAttachmentInput: CreateCanvaStickerImageAttachmentInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvaItemImageAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gmCreateCanvaStickerImageAttachment(companyId, createCanvaStickerImageAttachmentInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload a photo for bannerbear
         * @summary Upload a photo for bannerbear
         * @param {string} companyId The id of the company
         * @param {UploadBannerbearImageAttachmentInput} uploadBannerbearImageAttachmentInput Upload a photo for bannerbear
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gmUploadBannerbearImageAttachment(companyId: string, uploadBannerbearImageAttachmentInput: UploadBannerbearImageAttachmentInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadBannerbearImageAttachmentSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gmUploadBannerbearImageAttachment(companyId, uploadBannerbearImageAttachmentInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload image for canva
         * @summary Upload image for canva
         * @param {string} companyId The id of the company
         * @param {object} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gmUploadCanvaItemImageAttachment(companyId: string, file: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvaItemImageAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gmUploadCanvaItemImageAttachment(companyId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload image for canva template
         * @summary Upload image for canva template
         * @param {string} companyId The id of the company
         * @param {object} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gmUploadCanvaTemplateImageAttachment(companyId: string, file: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvaItemImageAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gmUploadCanvaTemplateImageAttachment(companyId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload the facebook image generated by bannerbear
         * @summary Upload the facebook image generated by bannerbear
         * @param {string} companyId The id of the company
         * @param {UploadBannerbearImageAttachmentInput} uploadBannerbearImageAttachmentInput Upload the facebook image generated by bannerbear
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gmUploadFacebookBannerbearImageAttachment(companyId: string, uploadBannerbearImageAttachmentInput: UploadBannerbearImageAttachmentInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadBannerbearImageAttachmentSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gmUploadFacebookBannerbearImageAttachment(companyId, uploadBannerbearImageAttachmentInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload a photo for facebook
         * @summary Upload a photo for facebook
         * @param {string} companyId The id of the company
         * @param {object} file 
         * @param {object} [extra] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gmUploadFacebookImageAttachment(companyId: string, file: object, extra?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gmUploadFacebookImageAttachment(companyId, file, extra, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload a photo for google
         * @summary Upload a photo for google
         * @param {string} companyId The id of the company
         * @param {object} file 
         * @param {object} [extra] 
         * @param {string} [placementType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gmUploadGoogleImageAttachment(companyId: string, file: object, extra?: object, placementType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gmUploadGoogleImageAttachment(companyId, file, extra, placementType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload the instagram image generated by bannerbear
         * @summary Upload the instagram image generated by bannerbear
         * @param {string} companyId The id of the company
         * @param {UploadBannerbearImageAttachmentInput} uploadBannerbearImageAttachmentInput Upload the instagram image generated by bannerbear
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gmUploadInstagramBannerbearImageAttachment(companyId: string, uploadBannerbearImageAttachmentInput: UploadBannerbearImageAttachmentInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadBannerbearImageAttachmentSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gmUploadInstagramBannerbearImageAttachment(companyId, uploadBannerbearImageAttachmentInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload a photo for instagram
         * @summary Upload a photo for instagram
         * @param {string} companyId The id of the company
         * @param {object} file 
         * @param {object} [extra] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gmUploadInstagramImageAttachment(companyId: string, file: object, extra?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gmUploadInstagramImageAttachment(companyId, file, extra, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImageAttachmentsGMApi - factory interface
 * @export
 */
export const ImageAttachmentsGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageAttachmentsGMApiFp(configuration)
    return {
        /**
         * Create image for canva configurable sticker
         * @summary Create image for canva configurable sticker
         * @param {string} companyId The id of the company
         * @param {CreateCanvaConfigurableStickerImageAttachmentInput} createCanvaConfigurableStickerImageAttachmentInput Create image for canva configurable sticker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmCreateCanvaConfigurableStickerImageAttachment(companyId: string, createCanvaConfigurableStickerImageAttachmentInput: CreateCanvaConfigurableStickerImageAttachmentInput, options?: any): AxiosPromise<CanvaItemImageAttachment> {
            return localVarFp.gmCreateCanvaConfigurableStickerImageAttachment(companyId, createCanvaConfigurableStickerImageAttachmentInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Create image for canva sticker
         * @summary Create image for canva sticker
         * @param {string} companyId The id of the company
         * @param {CreateCanvaStickerImageAttachmentInput} createCanvaStickerImageAttachmentInput Create image for canva sticker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmCreateCanvaStickerImageAttachment(companyId: string, createCanvaStickerImageAttachmentInput: CreateCanvaStickerImageAttachmentInput, options?: any): AxiosPromise<CanvaItemImageAttachment> {
            return localVarFp.gmCreateCanvaStickerImageAttachment(companyId, createCanvaStickerImageAttachmentInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a photo for bannerbear
         * @summary Upload a photo for bannerbear
         * @param {string} companyId The id of the company
         * @param {UploadBannerbearImageAttachmentInput} uploadBannerbearImageAttachmentInput Upload a photo for bannerbear
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadBannerbearImageAttachment(companyId: string, uploadBannerbearImageAttachmentInput: UploadBannerbearImageAttachmentInput, options?: any): AxiosPromise<UploadBannerbearImageAttachmentSuccessResponse> {
            return localVarFp.gmUploadBannerbearImageAttachment(companyId, uploadBannerbearImageAttachmentInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload image for canva
         * @summary Upload image for canva
         * @param {string} companyId The id of the company
         * @param {object} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadCanvaItemImageAttachment(companyId: string, file: object, options?: any): AxiosPromise<CanvaItemImageAttachment> {
            return localVarFp.gmUploadCanvaItemImageAttachment(companyId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload image for canva template
         * @summary Upload image for canva template
         * @param {string} companyId The id of the company
         * @param {object} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadCanvaTemplateImageAttachment(companyId: string, file: object, options?: any): AxiosPromise<CanvaItemImageAttachment> {
            return localVarFp.gmUploadCanvaTemplateImageAttachment(companyId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload the facebook image generated by bannerbear
         * @summary Upload the facebook image generated by bannerbear
         * @param {string} companyId The id of the company
         * @param {UploadBannerbearImageAttachmentInput} uploadBannerbearImageAttachmentInput Upload the facebook image generated by bannerbear
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadFacebookBannerbearImageAttachment(companyId: string, uploadBannerbearImageAttachmentInput: UploadBannerbearImageAttachmentInput, options?: any): AxiosPromise<UploadBannerbearImageAttachmentSuccessResponse> {
            return localVarFp.gmUploadFacebookBannerbearImageAttachment(companyId, uploadBannerbearImageAttachmentInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a photo for facebook
         * @summary Upload a photo for facebook
         * @param {string} companyId The id of the company
         * @param {object} file 
         * @param {object} [extra] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadFacebookImageAttachment(companyId: string, file: object, extra?: object, options?: any): AxiosPromise<ImageAttachment> {
            return localVarFp.gmUploadFacebookImageAttachment(companyId, file, extra, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a photo for google
         * @summary Upload a photo for google
         * @param {string} companyId The id of the company
         * @param {object} file 
         * @param {object} [extra] 
         * @param {string} [placementType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadGoogleImageAttachment(companyId: string, file: object, extra?: object, placementType?: string, options?: any): AxiosPromise<ImageAttachment> {
            return localVarFp.gmUploadGoogleImageAttachment(companyId, file, extra, placementType, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload the instagram image generated by bannerbear
         * @summary Upload the instagram image generated by bannerbear
         * @param {string} companyId The id of the company
         * @param {UploadBannerbearImageAttachmentInput} uploadBannerbearImageAttachmentInput Upload the instagram image generated by bannerbear
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadInstagramBannerbearImageAttachment(companyId: string, uploadBannerbearImageAttachmentInput: UploadBannerbearImageAttachmentInput, options?: any): AxiosPromise<UploadBannerbearImageAttachmentSuccessResponse> {
            return localVarFp.gmUploadInstagramBannerbearImageAttachment(companyId, uploadBannerbearImageAttachmentInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a photo for instagram
         * @summary Upload a photo for instagram
         * @param {string} companyId The id of the company
         * @param {object} file 
         * @param {object} [extra] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadInstagramImageAttachment(companyId: string, file: object, extra?: object, options?: any): AxiosPromise<ImageAttachment> {
            return localVarFp.gmUploadInstagramImageAttachment(companyId, file, extra, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImageAttachmentsGMApi - interface
 * @export
 * @interface ImageAttachmentsGMApi
 */
export interface ImageAttachmentsGMApiInterface {
    /**
     * Create image for canva configurable sticker
     * @summary Create image for canva configurable sticker
     * @param {string} companyId The id of the company
     * @param {CreateCanvaConfigurableStickerImageAttachmentInput} createCanvaConfigurableStickerImageAttachmentInput Create image for canva configurable sticker
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApiInterface
     */
    gmCreateCanvaConfigurableStickerImageAttachment(companyId: string, createCanvaConfigurableStickerImageAttachmentInput: CreateCanvaConfigurableStickerImageAttachmentInput, options?: any): AxiosPromise<CanvaItemImageAttachment>;

    /**
     * Create image for canva sticker
     * @summary Create image for canva sticker
     * @param {string} companyId The id of the company
     * @param {CreateCanvaStickerImageAttachmentInput} createCanvaStickerImageAttachmentInput Create image for canva sticker
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApiInterface
     */
    gmCreateCanvaStickerImageAttachment(companyId: string, createCanvaStickerImageAttachmentInput: CreateCanvaStickerImageAttachmentInput, options?: any): AxiosPromise<CanvaItemImageAttachment>;

    /**
     * Upload a photo for bannerbear
     * @summary Upload a photo for bannerbear
     * @param {string} companyId The id of the company
     * @param {UploadBannerbearImageAttachmentInput} uploadBannerbearImageAttachmentInput Upload a photo for bannerbear
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApiInterface
     */
    gmUploadBannerbearImageAttachment(companyId: string, uploadBannerbearImageAttachmentInput: UploadBannerbearImageAttachmentInput, options?: any): AxiosPromise<UploadBannerbearImageAttachmentSuccessResponse>;

    /**
     * Upload image for canva
     * @summary Upload image for canva
     * @param {string} companyId The id of the company
     * @param {object} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApiInterface
     */
    gmUploadCanvaItemImageAttachment(companyId: string, file: object, options?: any): AxiosPromise<CanvaItemImageAttachment>;

    /**
     * Upload image for canva template
     * @summary Upload image for canva template
     * @param {string} companyId The id of the company
     * @param {object} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApiInterface
     */
    gmUploadCanvaTemplateImageAttachment(companyId: string, file: object, options?: any): AxiosPromise<CanvaItemImageAttachment>;

    /**
     * Upload the facebook image generated by bannerbear
     * @summary Upload the facebook image generated by bannerbear
     * @param {string} companyId The id of the company
     * @param {UploadBannerbearImageAttachmentInput} uploadBannerbearImageAttachmentInput Upload the facebook image generated by bannerbear
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApiInterface
     */
    gmUploadFacebookBannerbearImageAttachment(companyId: string, uploadBannerbearImageAttachmentInput: UploadBannerbearImageAttachmentInput, options?: any): AxiosPromise<UploadBannerbearImageAttachmentSuccessResponse>;

    /**
     * Upload a photo for facebook
     * @summary Upload a photo for facebook
     * @param {string} companyId The id of the company
     * @param {object} file 
     * @param {object} [extra] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApiInterface
     */
    gmUploadFacebookImageAttachment(companyId: string, file: object, extra?: object, options?: any): AxiosPromise<ImageAttachment>;

    /**
     * Upload a photo for google
     * @summary Upload a photo for google
     * @param {string} companyId The id of the company
     * @param {object} file 
     * @param {object} [extra] 
     * @param {string} [placementType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApiInterface
     */
    gmUploadGoogleImageAttachment(companyId: string, file: object, extra?: object, placementType?: string, options?: any): AxiosPromise<ImageAttachment>;

    /**
     * Upload the instagram image generated by bannerbear
     * @summary Upload the instagram image generated by bannerbear
     * @param {string} companyId The id of the company
     * @param {UploadBannerbearImageAttachmentInput} uploadBannerbearImageAttachmentInput Upload the instagram image generated by bannerbear
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApiInterface
     */
    gmUploadInstagramBannerbearImageAttachment(companyId: string, uploadBannerbearImageAttachmentInput: UploadBannerbearImageAttachmentInput, options?: any): AxiosPromise<UploadBannerbearImageAttachmentSuccessResponse>;

    /**
     * Upload a photo for instagram
     * @summary Upload a photo for instagram
     * @param {string} companyId The id of the company
     * @param {object} file 
     * @param {object} [extra] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApiInterface
     */
    gmUploadInstagramImageAttachment(companyId: string, file: object, extra?: object, options?: any): AxiosPromise<ImageAttachment>;

}

/**
 * ImageAttachmentsGMApi - object-oriented interface
 * @export
 * @class ImageAttachmentsGMApi
 * @extends {BaseAPI}
 */
export class ImageAttachmentsGMApi extends BaseAPI implements ImageAttachmentsGMApiInterface {
    /**
     * Create image for canva configurable sticker
     * @summary Create image for canva configurable sticker
     * @param {string} companyId The id of the company
     * @param {CreateCanvaConfigurableStickerImageAttachmentInput} createCanvaConfigurableStickerImageAttachmentInput Create image for canva configurable sticker
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApi
     */
    public gmCreateCanvaConfigurableStickerImageAttachment(companyId: string, createCanvaConfigurableStickerImageAttachmentInput: CreateCanvaConfigurableStickerImageAttachmentInput, options?: any) {
        return ImageAttachmentsGMApiFp(this.configuration).gmCreateCanvaConfigurableStickerImageAttachment(companyId, createCanvaConfigurableStickerImageAttachmentInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create image for canva sticker
     * @summary Create image for canva sticker
     * @param {string} companyId The id of the company
     * @param {CreateCanvaStickerImageAttachmentInput} createCanvaStickerImageAttachmentInput Create image for canva sticker
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApi
     */
    public gmCreateCanvaStickerImageAttachment(companyId: string, createCanvaStickerImageAttachmentInput: CreateCanvaStickerImageAttachmentInput, options?: any) {
        return ImageAttachmentsGMApiFp(this.configuration).gmCreateCanvaStickerImageAttachment(companyId, createCanvaStickerImageAttachmentInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a photo for bannerbear
     * @summary Upload a photo for bannerbear
     * @param {string} companyId The id of the company
     * @param {UploadBannerbearImageAttachmentInput} uploadBannerbearImageAttachmentInput Upload a photo for bannerbear
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApi
     */
    public gmUploadBannerbearImageAttachment(companyId: string, uploadBannerbearImageAttachmentInput: UploadBannerbearImageAttachmentInput, options?: any) {
        return ImageAttachmentsGMApiFp(this.configuration).gmUploadBannerbearImageAttachment(companyId, uploadBannerbearImageAttachmentInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload image for canva
     * @summary Upload image for canva
     * @param {string} companyId The id of the company
     * @param {object} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApi
     */
    public gmUploadCanvaItemImageAttachment(companyId: string, file: object, options?: any) {
        return ImageAttachmentsGMApiFp(this.configuration).gmUploadCanvaItemImageAttachment(companyId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload image for canva template
     * @summary Upload image for canva template
     * @param {string} companyId The id of the company
     * @param {object} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApi
     */
    public gmUploadCanvaTemplateImageAttachment(companyId: string, file: object, options?: any) {
        return ImageAttachmentsGMApiFp(this.configuration).gmUploadCanvaTemplateImageAttachment(companyId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload the facebook image generated by bannerbear
     * @summary Upload the facebook image generated by bannerbear
     * @param {string} companyId The id of the company
     * @param {UploadBannerbearImageAttachmentInput} uploadBannerbearImageAttachmentInput Upload the facebook image generated by bannerbear
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApi
     */
    public gmUploadFacebookBannerbearImageAttachment(companyId: string, uploadBannerbearImageAttachmentInput: UploadBannerbearImageAttachmentInput, options?: any) {
        return ImageAttachmentsGMApiFp(this.configuration).gmUploadFacebookBannerbearImageAttachment(companyId, uploadBannerbearImageAttachmentInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a photo for facebook
     * @summary Upload a photo for facebook
     * @param {string} companyId The id of the company
     * @param {object} file 
     * @param {object} [extra] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApi
     */
    public gmUploadFacebookImageAttachment(companyId: string, file: object, extra?: object, options?: any) {
        return ImageAttachmentsGMApiFp(this.configuration).gmUploadFacebookImageAttachment(companyId, file, extra, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a photo for google
     * @summary Upload a photo for google
     * @param {string} companyId The id of the company
     * @param {object} file 
     * @param {object} [extra] 
     * @param {string} [placementType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApi
     */
    public gmUploadGoogleImageAttachment(companyId: string, file: object, extra?: object, placementType?: string, options?: any) {
        return ImageAttachmentsGMApiFp(this.configuration).gmUploadGoogleImageAttachment(companyId, file, extra, placementType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload the instagram image generated by bannerbear
     * @summary Upload the instagram image generated by bannerbear
     * @param {string} companyId The id of the company
     * @param {UploadBannerbearImageAttachmentInput} uploadBannerbearImageAttachmentInput Upload the instagram image generated by bannerbear
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApi
     */
    public gmUploadInstagramBannerbearImageAttachment(companyId: string, uploadBannerbearImageAttachmentInput: UploadBannerbearImageAttachmentInput, options?: any) {
        return ImageAttachmentsGMApiFp(this.configuration).gmUploadInstagramBannerbearImageAttachment(companyId, uploadBannerbearImageAttachmentInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a photo for instagram
     * @summary Upload a photo for instagram
     * @param {string} companyId The id of the company
     * @param {object} file 
     * @param {object} [extra] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageAttachmentsGMApi
     */
    public gmUploadInstagramImageAttachment(companyId: string, file: object, extra?: object, options?: any) {
        return ImageAttachmentsGMApiFp(this.configuration).gmUploadInstagramImageAttachment(companyId, file, extra, options).then((request) => request(this.axios, this.basePath));
    }
}
