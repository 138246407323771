import { AxiosInstance } from 'axios';
import { useMutation } from 'react-query';

import { CanvaItemsGMApi } from '@app/api';
import { useAxios } from '@app/hooks';

export async function createMissingBranchCanvaStickers(
  companyId: string,
  canvaItemId: string,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CanvaItemsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.generateMissingStickers(companyId, canvaItemId).then((res) => res.data);
}

export default function useCreateMissingBranchCanvaStickers(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; canvaItemId: string }) =>
      createMissingBranchCanvaStickers(values.companyId, values.canvaItemId, axiosInstance),
    props
  );
}
