import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';
import { useAuth, useAxios } from '@app/hooks';

import { CanvaCategoriesGMApi } from 'api/api';

export function listCanvaSubCategories(companyId: string, canvaCategoryId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new CanvaCategoriesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listSubCategories(companyId, canvaCategoryId).then((res) => res.data);
}

export default function useListCanvaSubCategories({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { companyId } = useAuth();
  const { api: axiosInstance } = useAxios();

  return useQuery(['listCanvaSubCategories'], () => listCanvaSubCategories(companyId, params.canvaCategoryId, axiosInstance), props);
}
