/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CanvaCategoryListResponse } from '../model';
// @ts-ignore
import { CanvaSubCategoryInput } from '../model';
// @ts-ignore
import { CanvaSubCategoryListResponse } from '../model';
// @ts-ignore
import { CanvaSubCategorySuccessResponse } from '../model';
// @ts-ignore
import { PunditResponse } from '../model';
/**
 * CanvaCategoriesGMApi - axios parameter creator
 * @export
 */
export const CanvaCategoriesGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create sub category for canva category
         * @summary Create sub category for canva category
         * @param {string} companyId The id of the company
         * @param {string} canvaCategoryId The id of the canva category
         * @param {CanvaSubCategoryInput} canvaSubCategoryInput Create a sub category for canva category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubCategory: async (companyId: string, canvaCategoryId: string, canvaSubCategoryInput: CanvaSubCategoryInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createSubCategory', 'companyId', companyId)
            // verify required parameter 'canvaCategoryId' is not null or undefined
            assertParamExists('createSubCategory', 'canvaCategoryId', canvaCategoryId)
            // verify required parameter 'canvaSubCategoryInput' is not null or undefined
            assertParamExists('createSubCategory', 'canvaSubCategoryInput', canvaSubCategoryInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/canva_categories/{canva_category_id}/create_sub_category`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"canva_category_id"}}`, encodeURIComponent(String(canvaCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(canvaSubCategoryInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Destroy sub category for canva category
         * @summary Destroy sub category for canva category
         * @param {string} companyId The id of the company
         * @param {string} canvaCategoryId The id of the canva category
         * @param {string} canvaSubCategoryId The id of the canva sub category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySubCategory: async (companyId: string, canvaCategoryId: string, canvaSubCategoryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('destroySubCategory', 'companyId', companyId)
            // verify required parameter 'canvaCategoryId' is not null or undefined
            assertParamExists('destroySubCategory', 'canvaCategoryId', canvaCategoryId)
            // verify required parameter 'canvaSubCategoryId' is not null or undefined
            assertParamExists('destroySubCategory', 'canvaSubCategoryId', canvaSubCategoryId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/canva_categories/{canva_category_id}/canva_sub_categories/{canva_sub_category_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"canva_category_id"}}`, encodeURIComponent(String(canvaCategoryId)))
                .replace(`{${"canva_sub_category_id"}}`, encodeURIComponent(String(canvaSubCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List canva categories for company
         * @summary List canva categories for company
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('list', 'companyId', companyId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/canva_categories`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List sub categories for canva category
         * @summary List sub categories for canva category
         * @param {string} companyId The id of the company
         * @param {string} canvaCategoryId The id of the canva category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubCategories: async (companyId: string, canvaCategoryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listSubCategories', 'companyId', companyId)
            // verify required parameter 'canvaCategoryId' is not null or undefined
            assertParamExists('listSubCategories', 'canvaCategoryId', canvaCategoryId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/canva_categories/{canva_category_id}/list_sub_categories`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"canva_category_id"}}`, encodeURIComponent(String(canvaCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Show canva sub category
         * @summary Show canva sub category
         * @param {string} companyId The id of the company
         * @param {string} canvaSubCategoryId The id of the canva sub category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        show: async (companyId: string, canvaSubCategoryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('show', 'companyId', companyId)
            // verify required parameter 'canvaSubCategoryId' is not null or undefined
            assertParamExists('show', 'canvaSubCategoryId', canvaSubCategoryId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/canva_sub_categories/{canva_sub_category_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"canva_sub_category_id"}}`, encodeURIComponent(String(canvaSubCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CanvaCategoriesGMApi - functional programming interface
 * @export
 */
export const CanvaCategoriesGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CanvaCategoriesGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Create sub category for canva category
         * @summary Create sub category for canva category
         * @param {string} companyId The id of the company
         * @param {string} canvaCategoryId The id of the canva category
         * @param {CanvaSubCategoryInput} canvaSubCategoryInput Create a sub category for canva category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubCategory(companyId: string, canvaCategoryId: string, canvaSubCategoryInput: CanvaSubCategoryInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvaSubCategorySuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubCategory(companyId, canvaCategoryId, canvaSubCategoryInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Destroy sub category for canva category
         * @summary Destroy sub category for canva category
         * @param {string} companyId The id of the company
         * @param {string} canvaCategoryId The id of the canva category
         * @param {string} canvaSubCategoryId The id of the canva sub category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroySubCategory(companyId: string, canvaCategoryId: string, canvaSubCategoryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroySubCategory(companyId, canvaCategoryId, canvaSubCategoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List canva categories for company
         * @summary List canva categories for company
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvaCategoryListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List sub categories for canva category
         * @summary List sub categories for canva category
         * @param {string} companyId The id of the company
         * @param {string} canvaCategoryId The id of the canva category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSubCategories(companyId: string, canvaCategoryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvaSubCategoryListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSubCategories(companyId, canvaCategoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Show canva sub category
         * @summary Show canva sub category
         * @param {string} companyId The id of the company
         * @param {string} canvaSubCategoryId The id of the canva sub category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async show(companyId: string, canvaSubCategoryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CanvaSubCategorySuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.show(companyId, canvaSubCategoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CanvaCategoriesGMApi - factory interface
 * @export
 */
export const CanvaCategoriesGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CanvaCategoriesGMApiFp(configuration)
    return {
        /**
         * Create sub category for canva category
         * @summary Create sub category for canva category
         * @param {string} companyId The id of the company
         * @param {string} canvaCategoryId The id of the canva category
         * @param {CanvaSubCategoryInput} canvaSubCategoryInput Create a sub category for canva category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubCategory(companyId: string, canvaCategoryId: string, canvaSubCategoryInput: CanvaSubCategoryInput, options?: any): AxiosPromise<CanvaSubCategorySuccessResponse> {
            return localVarFp.createSubCategory(companyId, canvaCategoryId, canvaSubCategoryInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Destroy sub category for canva category
         * @summary Destroy sub category for canva category
         * @param {string} companyId The id of the company
         * @param {string} canvaCategoryId The id of the canva category
         * @param {string} canvaSubCategoryId The id of the canva sub category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySubCategory(companyId: string, canvaCategoryId: string, canvaSubCategoryId: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroySubCategory(companyId, canvaCategoryId, canvaSubCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * List canva categories for company
         * @summary List canva categories for company
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(companyId: string, options?: any): AxiosPromise<CanvaCategoryListResponse> {
            return localVarFp.list(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * List sub categories for canva category
         * @summary List sub categories for canva category
         * @param {string} companyId The id of the company
         * @param {string} canvaCategoryId The id of the canva category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSubCategories(companyId: string, canvaCategoryId: string, options?: any): AxiosPromise<CanvaSubCategoryListResponse> {
            return localVarFp.listSubCategories(companyId, canvaCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Show canva sub category
         * @summary Show canva sub category
         * @param {string} companyId The id of the company
         * @param {string} canvaSubCategoryId The id of the canva sub category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        show(companyId: string, canvaSubCategoryId: string, options?: any): AxiosPromise<CanvaSubCategorySuccessResponse> {
            return localVarFp.show(companyId, canvaSubCategoryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CanvaCategoriesGMApi - interface
 * @export
 * @interface CanvaCategoriesGMApi
 */
export interface CanvaCategoriesGMApiInterface {
    /**
     * Create sub category for canva category
     * @summary Create sub category for canva category
     * @param {string} companyId The id of the company
     * @param {string} canvaCategoryId The id of the canva category
     * @param {CanvaSubCategoryInput} canvaSubCategoryInput Create a sub category for canva category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaCategoriesGMApiInterface
     */
    createSubCategory(companyId: string, canvaCategoryId: string, canvaSubCategoryInput: CanvaSubCategoryInput, options?: any): AxiosPromise<CanvaSubCategorySuccessResponse>;

    /**
     * Destroy sub category for canva category
     * @summary Destroy sub category for canva category
     * @param {string} companyId The id of the company
     * @param {string} canvaCategoryId The id of the canva category
     * @param {string} canvaSubCategoryId The id of the canva sub category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaCategoriesGMApiInterface
     */
    destroySubCategory(companyId: string, canvaCategoryId: string, canvaSubCategoryId: string, options?: any): AxiosPromise<void>;

    /**
     * List canva categories for company
     * @summary List canva categories for company
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaCategoriesGMApiInterface
     */
    list(companyId: string, options?: any): AxiosPromise<CanvaCategoryListResponse>;

    /**
     * List sub categories for canva category
     * @summary List sub categories for canva category
     * @param {string} companyId The id of the company
     * @param {string} canvaCategoryId The id of the canva category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaCategoriesGMApiInterface
     */
    listSubCategories(companyId: string, canvaCategoryId: string, options?: any): AxiosPromise<CanvaSubCategoryListResponse>;

    /**
     * Show canva sub category
     * @summary Show canva sub category
     * @param {string} companyId The id of the company
     * @param {string} canvaSubCategoryId The id of the canva sub category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaCategoriesGMApiInterface
     */
    show(companyId: string, canvaSubCategoryId: string, options?: any): AxiosPromise<CanvaSubCategorySuccessResponse>;

}

/**
 * CanvaCategoriesGMApi - object-oriented interface
 * @export
 * @class CanvaCategoriesGMApi
 * @extends {BaseAPI}
 */
export class CanvaCategoriesGMApi extends BaseAPI implements CanvaCategoriesGMApiInterface {
    /**
     * Create sub category for canva category
     * @summary Create sub category for canva category
     * @param {string} companyId The id of the company
     * @param {string} canvaCategoryId The id of the canva category
     * @param {CanvaSubCategoryInput} canvaSubCategoryInput Create a sub category for canva category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaCategoriesGMApi
     */
    public createSubCategory(companyId: string, canvaCategoryId: string, canvaSubCategoryInput: CanvaSubCategoryInput, options?: any) {
        return CanvaCategoriesGMApiFp(this.configuration).createSubCategory(companyId, canvaCategoryId, canvaSubCategoryInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Destroy sub category for canva category
     * @summary Destroy sub category for canva category
     * @param {string} companyId The id of the company
     * @param {string} canvaCategoryId The id of the canva category
     * @param {string} canvaSubCategoryId The id of the canva sub category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaCategoriesGMApi
     */
    public destroySubCategory(companyId: string, canvaCategoryId: string, canvaSubCategoryId: string, options?: any) {
        return CanvaCategoriesGMApiFp(this.configuration).destroySubCategory(companyId, canvaCategoryId, canvaSubCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List canva categories for company
     * @summary List canva categories for company
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaCategoriesGMApi
     */
    public list(companyId: string, options?: any) {
        return CanvaCategoriesGMApiFp(this.configuration).list(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List sub categories for canva category
     * @summary List sub categories for canva category
     * @param {string} companyId The id of the company
     * @param {string} canvaCategoryId The id of the canva category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaCategoriesGMApi
     */
    public listSubCategories(companyId: string, canvaCategoryId: string, options?: any) {
        return CanvaCategoriesGMApiFp(this.configuration).listSubCategories(companyId, canvaCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Show canva sub category
     * @summary Show canva sub category
     * @param {string} companyId The id of the company
     * @param {string} canvaSubCategoryId The id of the canva sub category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CanvaCategoriesGMApi
     */
    public show(companyId: string, canvaSubCategoryId: string, options?: any) {
        return CanvaCategoriesGMApiFp(this.configuration).show(companyId, canvaSubCategoryId, options).then((request) => request(this.axios, this.basePath));
    }
}
